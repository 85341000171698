<template>
  <div class="login-outer forgot-outer verify-otp">
    <img class="login-logo" :src="shopTalkImg" />
    <!-- <img class="login-logo" src="@/assets/images/shopTalk.png" /> -->
    <h2 class="login-title">OTP Verification</h2>
    <div class="login-box-shadow">
    <b-form class="login-form text-left">
      <p class="text-center mb-4">An OTP has been sent to you on your Phone Number.</p>
      <p class="text-center mb-4 font-weight-bold">Enter the OTP Sent to {{ userData.contact_num }}</p>
      <div class="otpInput-outer">
        <v-otp-input
          ref="otpInput"
          input-classes="otp-input"
          separator="-"
          input-type="number"
          :num-inputs="6"
          :should-auto-focus="true"
          :is-input-num="true"
          @on-change="OnChangeOTP"
          @on-complete="OnCompleteOTP"
        />
      </div>
      <div class="clearfix"></div>
      <p class="text-center mt-4">Didn't Get the OTP?<span @click.prevent="resendOTP" class="mb-1 login-forgot">Resend OTP</span></p>
      <div class="clearfix"></div>
      <div class="text-center">
        <b-spinner v-show="messsage_loader" variant="primary" label="Spinning"></b-spinner>
      </div>
      <b-alert v-model="success" variant="success">{{ message }}</b-alert>
      <b-alert v-model="fail" variant="danger">{{ message }}</b-alert>
      <b-button :disabled="validOTP == false" @click.prevent="onSubmit" type="button" class="login-submit mb-3" variant="light">Verify and Proceed</b-button>
      <div class="clearfix"></div>
    </b-form>
    </div>
    <p class="powered-by">POWERED BY</p>
    <img class="poweredby-logo" :src="poweredByImg">
    <!-- <img class="poweredby-logo" src="@/assets/images/frontlineDynamics.png"> -->
  </div>
</template>
<script>
  import axios from "axios";
  import { BASE_URL_VER } from "../../../../constants/app-constants"
  export default {
    data() {
      return {
        userData: "",
        success: false,
        fail: false,
        messsage_loader: false,
        message: "",
        validOTP: false,
        otpVal: "",
        shopTalkImg: require(`@/assets/images/shop-talk-logo.png`),
        poweredByImg: require(`@/assets/images/orga-logo-full.png`)
      };
    },
    beforeMount() {
      if ( this.$cookies.isKey("__uvd") == true ) {
        this.userData = this.$cookies.get( "__uvd" );
        this.userData.contact_num = (this.userData.contact_num) ? this.userData.contact_num.replace(/.(?=.{4})/g, 'x') : "";
      } else
        this.$router.push({ path: "/login/forgot-password" });
    },
    methods: {
      OnCompleteOTP(value) {
        this.validOTP = true;
        this.otpVal = value;
      },
      OnChangeOTP(value) {
        this.validOTP = false;
        this.otpVal = "";
      },
      resendOTP() {
        this.messsage_loader = true;
        let forgot_password = {
          option: 2,
          username: this.userData.username,
          first_name: "",
          last_name: "",
          station: "",
          remark: ""
        };
        axios
          .put( BASE_URL_VER + "users/forgot_password", forgot_password )
          .then(result => {
            this.messsage_loader = false;
            if (result.status == 200) {
              this.fail = false;
              this.success = true;
              this.message = result.data.response;
              setTimeout(() => {
                this.success = false;
                this.message = "";
              }, 3000);
            }
          })
          .catch(err => {
            this.messsage_loader = false;
            this.success = false;
            this.fail = true;
            this.message = err.response.data.response;
            setTimeout(() => {
              this.fail = false;
              this.message = "";
            }, 3000);
          });
      },
      onSubmit() {
        this.messsage_loader = true;
        axios
          .get( BASE_URL_VER + "users/validate_otp/" + this.userData.public_id + '?otp=' + this.otpVal )
          .then(result => {
            this.messsage_loader = false;
            if (result.status == 200) {
              this.fail = false;
              this.success = true;
              this.message = result.data.reponse;
              let token = result.data.token;
              setTimeout(() => {
                this.success = false;
                this.message = "";
                if ( this.$cookies.isKey("__uvd") == true )
                  this.$cookies.remove( "__uvd" );
                window.location.replace( token );
              }, 3000);
            }
          })
          .catch(err => {
            this.messsage_loader = false;
            this.success = false;
            this.fail = true;
            this.message = err.response.data.reponse;
            setTimeout(() => {
              this.fail = false;
              this.message = "";
            }, 3000);
          });
      }
    }
  };
</script>
<style>
.verify-otp .forgot-tabs .nav-tabs {border: none;margin: 30px 0 20px;}
.verify-otp .forgot-tabs a.nav-link {background: #f1f1f1;color: #003261;border-radius: 0;padding: 8px;}
.verify-otp .forgot-tabs a.nav-link.active {background: #003261;color: white;    border-color: #003261;}
.verify-otp .forgot-tabs .nav-item {width: 50%;}
.verify-otp .no-username.def-func-input-group input, .verify-otp .no-username.def-func-input-group select, .verify-otp .no-username input.th-input {border: 1px solid #a9a9a9;height: 40px;border-radius: 5px !important;width: 100%;padding: 7px 15px;}
.verify-otp .def-func-input-form-group label {font-size: 14px;color: #999999;display: block;margin-bottom: 7px;text-align: left;}
.verify-otp .def-func-input-group {display: table;width: 100%;table-layout: fixed;}
.verify-otp .def-func-input-group-col {margin-bottom: 25px;}
.verify-otp .def-func-input-group input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #cccccc;
}
.verify-otp .def-func-input-group input::-moz-placeholder {
  /* Firefox 19+ */
  color: #cccccc;
}
.verify-otp .def-func-input-group input:-ms-input-placeholder {
  /* IE 10+ */
  color: #cccccc;
}
.verify-otp .def-func-input-group input:-moz-placeholder {
  /* Firefox 18- */
  color: #cccccc;
}
.verify-otp .def-func-input-group textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #cccccc;
}
.verify-otp .def-func-input-group textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #cccccc;
}
.verify-otp .def-func-input-group textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #cccccc;
}
.verify-otp .def-func-input-group textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #cccccc;
}
.verify-otp .custom-control-input:checked ~ .custom-control-label::before, .verify-otp .custom-control-input.is-valid:checked ~ .custom-control-label::before {border-color: #003261;background-color: #003261;}
.verify-otp .forgot-tabs {padding: 5% 10%;border: 1px solid lightgrey;margin: 30px 0;border-radius: 5px;box-shadow: 0 0 5px lightgrey;}
.login-outer.forgot-outer.verify-otp {max-width: 525px;}
.verify-otp .login-submit {margin: 0px auto 25px;width: auto;padding: 8px 21px 6px;}
.verify-otp .login-form .otp-input input {text-align: center;margin: 0 10px;width: auto;border: none;border-bottom: 1px solid lightgrey;box-shadow: none;}
.verify-otp .otp-input { width: 40px; height: 40px; padding: 5px; margin: 0 8px; font-size: 20px; border-radius: 4px; border: 1px solid rgba(0, 0, 0, 0.3); text-align: center;}
.verify-otp .otp-input::-webkit-inner-spin-button, .otp-input::-webkit-outer-spin-button { -webkit-appearance: none; margin: 0; }
.verify-otp .login-box-shadow {padding: 5% 10%;border: 1px solid lightgrey;margin: 30px 0;border-radius: 5px;box-shadow: 0 0 5px lightgrey;}
.verify-otp .login-form .otp-input input::placeholder {color: #e6e6e6;}
.verify-otp .login-form .otp-input::after {border-bottom: none;}
.verify-otp .input-group.my-5.otp-input {justify-content: center;}
.verify-otp .login-submit:disabled {background: lightgrey;}
.verify-otp .otpInput-outer {display: flex; flex-direction: row;justify-content: center;margin: 30px 0;}
.verify-otp .otp-input:focus {-webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);outline: none;}
</style>