<template>
  <div class="login-outer first-login-outer">
    <img class="login-logo" :src="shopTalkImg" />
    <!-- <img class="login-logo" src="@/assets/images/shopTalk.png" /> -->
    <h2 class="login-title">Reset Password</h2>
    <ValidationObserver v-if="validToken" ref="observer" v-slot="{ passes }">
      <b-form class="first-login-form text-left" @submit.prevent="passes(onSubmit)">
        <div class="def-func-input-form-group">
            <div class="def-func-input-group">
                <div class="def-func-input-group-col mb-4">
                    <ValidationProvider name="Password" rules="required|min:8|verify_password" v-slot="{ valid, errors }">
                        <label>New Password</label>
                        <b-form-input
                        type="password"
                        :state="errors[0] ? false : (valid ? true : null)"
                        v-model="reset_password.password"
                        ></b-form-input>
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </ValidationProvider>
                </div>
                <div class="def-func-input-group-col mb-4">
                    <ValidationProvider
                    name="Confirm Password"
                    rules="required|min:8"
                    v-slot="{ valid, errors }"
                    >
                      <label>Confirm Password</label>
                      <b-form-input
                      type="password"
                      :state="errors[0] ? false : (valid ? true : null)"
                      v-model="reset_password.confirmpassword"
                      ></b-form-input>
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </ValidationProvider>
                </div>
                <div
                style="color:red"
                v-if="(reset_password.password !== reset_password.confirmpassword) && issubmitted"
                >Password does not match</div>
                <div class="clearfix"></div>
                <div class="text-center">
                  <b-spinner v-show="messsage_loader" variant="primary" label="Spinning"></b-spinner>
                </div>
                <b-alert v-model="success" variant="success">{{message}}</b-alert>
                <b-alert v-model="fail" variant="danger">{{message}}</b-alert>
                <b-button type="submit" class="login-submit" variant="light">Submit</b-button>
            </div>
        </div>
      </b-form>
    </ValidationObserver>
    <div v-else class="outer_reset outer_404">
      <div class="inner_404">
        <div class="title_404">Invalid Token</div>
        <div class="footer_404">
          <div class="body_404">Redirecting to login page in <strong>{{secondsTimer + 1 | secondText }}</strong></div>
          <b-progress :max="max" height="1.5rem" animated striped>
            <b-progress-bar :value="timerValue" class="progress-label">
            </b-progress-bar>
          </b-progress>
        </div>
      </div>
    </div>
    <b-alert variant="warning">Warning Alert</b-alert>
    <div class="text-center spinner-div overlay" v-show="page_loader">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { BASE_URL_VER } from "../../../../constants/app-constants"
export default {
  data() {
    return {
      success: false,
      fail: false,
      messsage_loader: false,
      message: "",
      reset_password: {
        password: "",
        confirmpassword: ""
      },
      issubmitted: false,
      validToken: true,
      page_loader: false,
      secondsTimer: 4,
      timerValue: 0,
      max: 5,
      timer: null,
      shopTalkImg: require(`@/assets/images/shop-talk-logo.png`),
    };
  },
  filters: {
    secondText(val) {
      return ( val == 1 || val == 0 ) ? val + " second" : val + " seconds"
    }
  },
  components: {},
  beforeCreate() {
    if ( this.$cookies.isKey("__uvd") == true )
      this.$cookies.remove( "__uvd" );
  },
  methods: {
    onSubmit() {
      this.issubmitted = true;
      if ( this.reset_password.password === this.reset_password.confirmpassword ) {
        this.issubmitted = false;
        this.messsage_loader = true;
        let formdata = {
          token: this.$route.params.resettoken,
          password: this.reset_password.password,
          confirm_password: this.reset_password.confirmpassword
        };
        axios
          .put(BASE_URL_VER + "users/set_password", formdata)
          .then(result => {
            this.messsage_loader = false;
            if (result.data.code == 200) {
              this.success = true;
              this.fail = false;
              this.message = result.data.response;
              setTimeout(() => {
                this.success = false;
                this.message = "";
                this.$router.push({path:"/login"})
              }, 3000);
            }
          })
          .catch(err => {
            this.messsage_loader = false;
            if ( err.response.status == 400 ) {
              this.success = false;
              this.fail = true;
              this.message = err.response.data.response;
              setTimeout(() => {
                this.fail = false;
                this.message = "";
              }, 3000);
            }
          });
      }
    }
  },
  beforeMount() {
    this.page_loader = true
    let jsonData = {
      token: this.$route.params.resettoken
    };
    axios
      .post( BASE_URL_VER + "users/check_token_validity", jsonData )
      .then(result => {
        this.page_loader = false;
        if ( result.data.code == 200 ) {
          this.validToken = true;
        }
      })
      .catch(err => {
        this.page_loader = false;
        if ( err.response.status == 400 ) {
          this.validToken = false;
            this.timer = setInterval(() => {
              if ( this.secondsTimer <= -1 ) {
                clearInterval(this.timer);
                this.$router.push({path:"/login"})
              }
              this.timerValue = this.max - this.secondsTimer;
              this.secondsTimer -= 1;
            }, 1000)
        }
      });
  }
};
</script>
<style scoped>
  @import "../../login.css";
  @import "../../404.css";
  .login-outer.first-login-outer {max-width: 590px;}
  .login-outer.first-login-outer .first-login-form {max-width: 340px;margin: 30px auto 0;}
  .first-login-outer h2.login-title {font-size: 17px;font-weight: normal;border-bottom: none;line-height: normal;}
  .phone-icon svg {font-size: 20px;}
  .def-func-input-form-group label {font-size: 14px;color: #999999;display: block;margin-bottom: 7px;text-align: left;}
  .def-func-input-group {width: 100%;display: block;table-layout: auto;}
  .def-func-input-group-col {margin-bottom: 25px;width: 100%;padding: 0;display: block;}
  .def-func-input-group input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #cccccc;
  }
  .def-func-input-group input::-moz-placeholder {
    /* Firefox 19+ */
    color: #cccccc;
  }
  .def-func-input-group input:-ms-input-placeholder {
    /* IE 10+ */
    color: #cccccc;
  }
  .def-func-input-group input:-moz-placeholder {
    /* Firefox 18- */
    color: #cccccc;
  }
  .def-func-input-group textarea::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #cccccc;
  }
  .def-func-input-group textarea::-moz-placeholder {
    /* Firefox 19+ */
    color: #cccccc;
  }
  .def-func-input-group textarea:-ms-input-placeholder {
    /* IE 10+ */
    color: #cccccc;
  }
  .def-func-input-group textarea:-moz-placeholder {
    /* Firefox 18- */
    color: #cccccc;
  }
  .cell-phone-outer {
  display: flex;
  }
  .cell-phone-outer > input.cell-prefix {
    max-width: 65px;
    margin-right: 10px;
    text-align: center;
    padding: 7px 15px 7px 12px;
  }
  .cell-phone-outer > span {
    width: 100%;
  }

  .non-username-note {
      background: #d1ecf1;
      border: 1px solid #bee5eb;
      border-radius: .25rem;
      padding: .75rem 1.25rem;
      color: #0c5460;
      margin-bottom: 25px;
  }
  .token-expired {background-color: transparent;border-color: transparent;color: #a94442;}
  .token-expired svg {
      width: 100px !important;
      font-size: 55px;
  }
  .token-expired p {margin: 0;font-size: 16px;}
  .outer_reset .inner_404 {
      position: relative;
      top: initial;
      transform: translateY(0);
      padding: 25px 30px 0;
  }
  .outer_reset .btn {
      background: white;
      border: 1px solid #003261;
      border-radius: 5px;
      color: #031d38;
      padding: 0 15px;
      height: 40px;
      position: relative;
  }
  .progress-bar.progress-label {
      flex-direction: initial;
      padding: 3px;
      font-size: 15px;
      background-color: #003261;
  }
  .progress-bar.progress-label strong {
      line-height: 24px;
      padding: 0 1px 0 5px;
  }
  .outer_reset .body_404 {margin: 30px 0 20px;}
  .outer_reset .body_404 strong { font-size: 20px; }
  .title_404 {
      font-size: 30px;
      letter-spacing: 0px;
  }
  .first-login-outer h2.login-title {
    font-size: 28px;
    border-bottom: 1px solid #d7d7d7;
    font-weight: 500;
  }
</style>
