<template>
  <header class="th-header">
    <div class="container">
      <div class="header-outter">
        <div class="menu-outter header-col">
          <b-img v-if="loggedin_user_details.passwordCheck != 1" @click="OnSlideMenu()" :src='logoImg'></b-img>
          <!-- <b-img v-if="loggedin_user_details.passwordCheck != 1" @click="OnSlideMenu()" :src='require(`@/assets/images/logo.png`)'></b-img> -->
        </div>
        <div class="page-title-outter header-col">
          <h3>{{ header }}</h3>
        </div>
        <div class="logo-outter header-col Mainlogo tristar-logo">
          <b-img :src='mainLogoImg'></b-img>
          <!-- <b-img :src='require(`@/assets/images/polar-logo.jpg`)'></b-img> -->
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import { EventBus } from "../../main";
import { isloggedin } from "../../constants/app-constants";
export default {
  data() {
    return {
      header: "",
      MenuClicked: false,
      loggedin_user_details: isloggedin,
      logoImg: require(`@/assets/images/orga-logo.png`),
      mainLogoImg: require(`@/assets/images/tristar-logo.png`)
    };
  },
  watch: {
    $route(to, from) {
      if (this.$route.params.type == "briefings" || this.$route.params.type == "videogramme" || this.$route.params.type == "trainings") {
        const title = this.$route.params.type
        let titleCap = title.charAt(0).toUpperCase() + title.slice(1)
        titleCap = titleCap.replace(new RegExp("s$","ig"), "");
        this.header = this.$route.meta.route_header + " " + titleCap
      } else {
        this.header = this.$route.meta.route_header;
      }
    }
  },
  created() {
    if (this.$route.params.type == "briefings" || this.$route.params.type == "videogramme" || this.$route.params.type == "trainings") {
      const title = this.$route.params.type
      const titleCap = title.charAt(0).toUpperCase() + title.slice(1)
      this.header = this.$route.meta.route_header+" "+titleCap
    } else {
      this.header = this.$route.meta.route_header;
    }
  },
  methods: {
    OnSlideMenu() {
      this.MenuClicked = !this.MenuClicked;
      EventBus.$emit("slideMenuClicked", this.MenuClicked);
    }
  }
};
</script>>

