<template>
    <div>
        <div id="pdf-outer" ref="content">
            <div class="certificate-tristar-logo">
                <img src="@/assets/images/tristar-logo.png">
            </div>
            <h3 id="cert-title">Certificate Of Training {{pdfDetails.user_id}}</h3>
            <h4 id="cert-name">{{pdfDetails.user_name}}</h4>
            <div id="pdf-inner">
                <p>Has completed the </p>
                <p class="upperCase">{{ pdfDetails.training_title }}</p>
                <p>{{pdfDetails.date_completed}}</p>
            </div>
            <div class="completed-module">
              <h5>Module Completed</h5>
              <ul class="module-outer">  
                <li class="module-row" v-for="(row,index) in pdfDetails.modules_completed" :key="index">
                    <div class="module-inner">{{row}}</div>
                </li>
              </ul>
            </div>
        </div>
        <div class="clearfix"></div>
        <div class="pdf-btns-inner">
            <b-button variant="light" @click="download(pdfDetails.user_name)" class="mt-5">Download</b-button>
        </div>
    </div>
</template>
<script>
import { EventBus } from "../../../main";
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';

export default {
    data() {
        return{
            selected: "true",
            pdfDetails: {
                user_name: "",
                training_title: "",
                date_completed: "",
                modules_completed: []
            }
        }
    },
    created: function() {
        EventBus.$on("downloadPDFClicked", pdfDetails => {
            this.pdfDetails = {
                user_name: pdfDetails.user_name,
                training_title: pdfDetails.training_title,
                date_completed: pdfDetails.date_completed,
                modules_completed: pdfDetails.modules_completed
            }
        });
    },
    methods: {
        download(username){
            const doc = new jsPDF('p', 'mm', [530, 750]);
            /** WITH CSS */
            var canvasElement = document.createElement('canvas');
            html2canvas(this.$refs.content, {scale: 1, scrollY: 0}).then(function (canvas) {
                const img = canvas.toDataURL("image/jpeg", 1.0);
                var imgWidth = 185; 
                var pageHeight = 262;
                var imgHeight = canvas.height * imgWidth / canvas.width;  
                var heightLeft = imgHeight;
                var position = 0;
                var width = doc.internal.pageSize.getWidth();
                var height = doc.internal.pageSize.getHeight();
                doc.addImage(img,'JPEG', 0, -1);
                doc.setDrawColor(72,88,130);
                doc.setLineWidth(4.0); 
                doc.rect(3, 3, doc.internal.pageSize.width - 6, doc.internal.pageSize.height - 6, 'S');
                heightLeft -= pageHeight;
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight + 1;
                    doc.addPage();
                    doc.addImage(img, 'JPEG', 0, position);
                    doc.setDrawColor(72,88,130);
                    doc.setLineWidth(4.0); 
                    doc.rect(3, 3, doc.internal.pageSize.width - 6, doc.internal.pageSize.height - 6, 'S');
                    heightLeft -= pageHeight;
                }
                username = username.replace(/\s+/g, '-').toLowerCase();
                let fileName = "Certificate_" + username + ".pdf";
                doc.save( fileName );
            });
        }
    }
}
</script>
<style scoped>
#pdf-outer {width: 707px;min-height: 710px;color: #003261;margin: auto;padding: 62px 50px;text-align: center;}
#cert-title {margin: 40px 0;font-size: 30px;}
#cert-name {font-weight: 800;margin: 50px 0;font-size: 22px;text-transform: capitalize;}
#pdf-inner {display: flex;flex-wrap: wrap;align-items: center;justify-content: center;padding: 0;margin: auto;}
#pdf-inner p {font-size: 16px;font-weight: 500;width: 100%;color: #485882;margin-bottom: 30px;}
.pdf-btns-inner {margin: auto;text-align: right;max-width: 710px;}
#pdf-outer {background: url(../../../assets/images/tristar-logo-watermark.png) top 300px center no-repeat;}
.completed-module h5 {color: #003261;font-weight: normal;text-align: left;padding: 25px 0 10px;font-size: 17px;border-bottom: 1px solid grey;}
.module-row {text-align: left;margin-bottom: 10px;font-size: 18px;}
.module-inner::first-letter {text-transform: uppercase;}
.module-inner {font-size: 16px;padding-right: 20px;}
.module-outer {column-count: 2;padding: 20px 0 0 19px;}
.certificate-tristar-logo img {max-width: 300px}
</style>