var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-sidebar"},[_c('h5',{staticClass:"sidebar-title"},[_vm._v("Set Access Rights")]),_c('h6',{staticClass:"sidebar-subtitle"},[_vm._v(_vm._s(_vm.grpName))]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return passes( _vm.popupBox )}}},[_c('b-tabs',{staticClass:"access-tabs",class:( _vm.loggedin_user_details.shuttle_tracker == 'TRUE' ) ? 'five-tabs' : 'four-tabs'},[_c('b-tab',{attrs:{"title":"Admin","id":"collapse-admin","accordion":"access-accordion"}},[_c('b-form-checkbox-group',{staticClass:"access-rights dc-access-label",attrs:{"name":"admin"},model:{value:(_vm.accessRightsData.accessRights),callback:function ($$v) {_vm.$set(_vm.accessRightsData, "accessRights", $$v)},expression:"accessRightsData.accessRights"}},[_c('b-form-checkbox',{attrs:{"value":"Define Functions"}},[_c('p',[_vm._v("Define Functions")])]),_c('b-form-checkbox',{attrs:{"value":"Organisation Set Up"}},[_c('p',[_vm._v("Organisation Set Up")])]),_c('b-form-checkbox',{attrs:{"value":"Set Up Station"}},[_c('p',[_vm._v("Set Up Stations")])]),_c('b-form-checkbox',{attrs:{"value":"Employee Groups"}},[_c('p',[_vm._v("Employee Groups")])]),_c('b-form-checkbox',{attrs:{"value":"Manage Users"}},[_c('b-row',[_c('b-col',{staticClass:"pr-0",attrs:{"sm":"12","md":"4","lg":"3"}},[_c('p',[_vm._v("Manage Users")])]),_c('b-col',{staticClass:"px-0 text-center",attrs:{"sm":"12","md":"8","lg":"9"}},[_c('ValidationProvider',{attrs:{"name":"Manage Users Scope","rules":("" + ((_vm.accessRightsData.accessRights.includes('Manage Users')) ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"state":errors[0] ? false : ( valid ? true : null ),"disabled":!_vm.accessRightsData.accessRights.includes('Manage Users'),"options":_vm.scopeOptions,"name":"manageUsers"},model:{value:(_vm.accessRightsData.geographicalRights.manageUsers),callback:function ($$v) {_vm.$set(_vm.accessRightsData.geographicalRights, "manageUsers", $$v)},expression:"accessRightsData.geographicalRights.manageUsers"}}),_c('b-form-invalid-feedback',{staticStyle:{"display":"block"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1),_c('b-tab',{attrs:{"title":"Briefing","id":"collapse-briefing","accordion":"access-accordion"}},[_c('b-form-checkbox-group',{staticClass:"access-rights dc-access-label",attrs:{"name":"admin"},model:{value:(_vm.accessRightsData.accessRights),callback:function ($$v) {_vm.$set(_vm.accessRightsData, "accessRights", $$v)},expression:"accessRightsData.accessRights"}},[_c('b-form-checkbox',{attrs:{"value":"Create Briefing"},on:{"input":function($event){return _vm.dependencyCheck($event)}}},[_c('b-row',[_c('b-col',{staticClass:"pr-0",attrs:{"sm":"12","md":"4","lg":"3"}},[_c('p',[_vm._v("Create Briefing")])]),_c('b-col',{staticClass:"px-0 text-center",attrs:{"sm":"12","md":"8","lg":"9"}},[_c('ValidationProvider',{attrs:{"name":"Create Briefing Scope","rules":("" + ((_vm.accessRightsData.accessRights.includes('Create Briefing')) ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"state":errors[0] ? false : ( valid ? true : null ),"disabled":!_vm.accessRightsData.accessRights.includes('Create Briefing'),"options":_vm.scopeOptions,"name":"createBriefing"},model:{value:(_vm.accessRightsData.geographicalRights.createBriefing),callback:function ($$v) {_vm.$set(_vm.accessRightsData.geographicalRights, "createBriefing", $$v)},expression:"accessRightsData.geographicalRights.createBriefing"}}),_c('b-form-invalid-feedback',{staticStyle:{"display":"block"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-form-checkbox',{attrs:{"value":"List Briefing","disabled":_vm.accessRightsData.accessRights.includes('Create Briefing')}},[_c('b-row',[_c('b-col',{staticClass:"pr-0",attrs:{"sm":"12","md":"4","lg":"3"}},[_c('p',[_vm._v("List Briefings")])]),_c('b-col',{staticClass:"px-0 text-center",attrs:{"sm":"12","md":"8","lg":"9"}},[_c('ValidationProvider',{attrs:{"name":"List Briefings Scope","rules":("" + ((_vm.accessRightsData.accessRights.includes('List Briefing')) ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"state":errors[0] ? false : ( valid ? true : null ),"disabled":!_vm.accessRightsData.accessRights.includes('List Briefing'),"options":_vm.scopeOptions,"name":"listBriefing"},model:{value:(_vm.accessRightsData.geographicalRights.listBriefing),callback:function ($$v) {_vm.$set(_vm.accessRightsData.geographicalRights, "listBriefing", $$v)},expression:"accessRightsData.geographicalRights.listBriefing"}}),_c('b-form-invalid-feedback',{staticStyle:{"display":"block"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-form-checkbox',{attrs:{"value":"Briefing Reports"}},[_c('b-row',[_c('b-col',{staticClass:"pr-0",attrs:{"sm":"12","md":"4","lg":"3"}},[_c('p',[_vm._v("Briefing Reports")])]),_c('b-col',{staticClass:"px-0 text-center",attrs:{"sm":"12","md":"8","lg":"9"}},[_c('ValidationProvider',{attrs:{"name":"Briefing Reports Scope","rules":("" + ((_vm.accessRightsData.accessRights.includes('Briefing Reports')) ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"state":errors[0] ? false : ( valid ? true : null ),"disabled":!_vm.accessRightsData.accessRights.includes('Briefing Reports'),"options":_vm.scopeOptions,"name":"briefingReports"},model:{value:(_vm.accessRightsData.geographicalRights.briefingReports),callback:function ($$v) {_vm.$set(_vm.accessRightsData.geographicalRights, "briefingReports", $$v)},expression:"accessRightsData.geographicalRights.briefingReports"}}),_c('b-form-invalid-feedback',{staticStyle:{"display":"block"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-form-checkbox',{attrs:{"value":"Upload Videogramme"},on:{"input":function($event){return _vm.dependencyCheck($event)}}},[_c('b-row',[_c('b-col',{staticClass:"pr-0",attrs:{"sm":"12","md":"4","lg":"3"}},[_c('p',[_vm._v("Upload Videogramme")])]),_c('b-col',{staticClass:"px-0 text-center",attrs:{"sm":"12","md":"8","lg":"9"}},[_c('ValidationProvider',{attrs:{"name":"Upload Videogramme Scope","rules":("" + ((_vm.accessRightsData.accessRights.includes('Upload Videogramme')) ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"state":errors[0] ? false : ( valid ? true : null ),"disabled":!_vm.accessRightsData.accessRights.includes('Upload Videogramme'),"options":_vm.scopeOptions,"name":"uploadVideogramme"},model:{value:(_vm.accessRightsData.geographicalRights.uploadVideogramme),callback:function ($$v) {_vm.$set(_vm.accessRightsData.geographicalRights, "uploadVideogramme", $$v)},expression:"accessRightsData.geographicalRights.uploadVideogramme"}}),_c('b-form-invalid-feedback',{staticStyle:{"display":"block"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-form-checkbox',{attrs:{"value":"List Videogrammes","disabled":_vm.accessRightsData.accessRights.includes('Upload Videogramme')}},[_c('b-row',[_c('b-col',{staticClass:"pr-0",attrs:{"sm":"12","md":"4","lg":"3"}},[_c('p',[_vm._v("List Videogrammes")])]),_c('b-col',{staticClass:"px-0 text-center",attrs:{"sm":"12","md":"8","lg":"9"}},[_c('ValidationProvider',{attrs:{"name":"List Videogrammes Scope","rules":("" + ((_vm.accessRightsData.accessRights.includes('List Videogrammes')) ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"state":errors[0] ? false : ( valid ? true : null ),"disabled":!_vm.accessRightsData.accessRights.includes('List Videogrammes'),"options":_vm.scopeOptions,"name":"listVideogrammes"},model:{value:(_vm.accessRightsData.geographicalRights.listVideogrammes),callback:function ($$v) {_vm.$set(_vm.accessRightsData.geographicalRights, "listVideogrammes", $$v)},expression:"accessRightsData.geographicalRights.listVideogrammes"}}),_c('b-form-invalid-feedback',{staticStyle:{"display":"block"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1),_c('b-tab',{attrs:{"title":"Checklist","id":"collapse-checklist","accordion":"access-accordion"}},[_c('b-form-checkbox-group',{staticClass:"access-rights dc-access-label",attrs:{"name":"admin"},model:{value:(_vm.accessRightsData.accessRights),callback:function ($$v) {_vm.$set(_vm.accessRightsData, "accessRights", $$v)},expression:"accessRightsData.accessRights"}},[_c('b-form-checkbox',{attrs:{"value":"Global Checklist"}},[_c('p',[_vm._v("Define Checklists & Global Database")])]),_c('b-form-checkbox',{attrs:{"value":"Set Up My Checklist"}},[_c('b-row',[_c('b-col',{staticClass:"pr-0",attrs:{"sm":"12","md":"4","lg":"3"}},[_c('p',[_vm._v("Set Up My Checklist")])]),_c('b-col',{staticClass:"px-0 text-center",attrs:{"sm":"12","md":"8","lg":"9"}},[_c('ValidationProvider',{attrs:{"name":"Set Up My Checklist Scope","rules":("" + ((_vm.accessRightsData.accessRights.includes('Set Up My Checklist')) ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"state":errors[0] ? false : ( valid ? true : null ),"disabled":!_vm.accessRightsData.accessRights.includes('Set Up My Checklist'),"options":_vm.scopeOptions,"name":"setUpMyChecklist"},model:{value:(_vm.accessRightsData.geographicalRights.setUpMyChecklist),callback:function ($$v) {_vm.$set(_vm.accessRightsData.geographicalRights, "setUpMyChecklist", $$v)},expression:"accessRightsData.geographicalRights.setUpMyChecklist"}}),_c('b-form-invalid-feedback',{staticStyle:{"display":"block"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-form-checkbox',{attrs:{"value":"Perform Checks"}},[_c('p',[_vm._v("Perform Checks")])]),_c('b-form-checkbox',{attrs:{"value":"Checklist Summary"}},[_c('b-row',[_c('b-col',{staticClass:"pr-0",attrs:{"sm":"12","md":"4","lg":"3"}},[_c('p',[_vm._v("Summary")])]),_c('b-col',{staticClass:"px-0 text-center",attrs:{"sm":"12","md":"8","lg":"9"}},[_c('ValidationProvider',{attrs:{"name":"Summary Scope","rules":("" + ((_vm.accessRightsData.accessRights.includes('Checklist Summary')) ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"state":errors[0] ? false : ( valid ? true : null ),"disabled":!_vm.accessRightsData.accessRights.includes('Checklist Summary'),"options":_vm.scopeOptions,"name":"checklistSummary"},model:{value:(_vm.accessRightsData.geographicalRights.checklistSummary),callback:function ($$v) {_vm.$set(_vm.accessRightsData.geographicalRights, "checklistSummary", $$v)},expression:"accessRightsData.geographicalRights.checklistSummary"}}),_c('b-form-invalid-feedback',{staticStyle:{"display":"block"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-form-checkbox',{attrs:{"value":"Retrieve Reports"}},[_c('b-row',[_c('b-col',{staticClass:"pr-0",attrs:{"sm":"12","md":"4","lg":"3"}},[_c('p',[_vm._v("Retrieve Reports")])]),_c('b-col',{staticClass:"px-0 text-center",attrs:{"sm":"12","md":"8","lg":"9"}},[_c('ValidationProvider',{attrs:{"name":"Retrieve Reports Scope","rules":("" + ((_vm.accessRightsData.accessRights.includes('Retrieve Reports')) ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"state":errors[0] ? false : ( valid ? true : null ),"disabled":!_vm.accessRightsData.accessRights.includes('Retrieve Reports'),"options":_vm.scopeOptions,"name":"retrieveReports"},model:{value:(_vm.accessRightsData.geographicalRights.retrieveReports),callback:function ($$v) {_vm.$set(_vm.accessRightsData.geographicalRights, "retrieveReports", $$v)},expression:"accessRightsData.geographicalRights.retrieveReports"}}),_c('b-form-invalid-feedback',{staticStyle:{"display":"block"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-form-checkbox',{attrs:{"value":"List Checklists"}},[_c('b-row',[_c('b-col',{staticClass:"pr-0",attrs:{"sm":"12","md":"4","lg":"3"}},[_c('p',[_vm._v("List Checklists")])]),_c('b-col',{staticClass:"px-0 text-center",attrs:{"sm":"12","md":"8","lg":"9"}},[_c('ValidationProvider',{attrs:{"name":"List Checklists Scope","rules":("" + ((_vm.accessRightsData.accessRights.includes('List Checklists')) ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"state":errors[0] ? false : ( valid ? true : null ),"disabled":!_vm.accessRightsData.accessRights.includes('List Checklists'),"options":_vm.checkScopeOptions,"name":"listChecklists"},model:{value:(_vm.accessRightsData.geographicalRights.listChecklists),callback:function ($$v) {_vm.$set(_vm.accessRightsData.geographicalRights, "listChecklists", $$v)},expression:"accessRightsData.geographicalRights.listChecklists"}},[_c('div',{staticClass:"dc-access-183-space custom-control-inline custom-radio"})]),_c('b-form-invalid-feedback',{staticStyle:{"display":"block"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1),_c('b-tab',{attrs:{"title":"Training","id":"collapse-training","accordion":"access-accordion"}},[_c('b-form-checkbox-group',{staticClass:"access-rights dc-access-label",attrs:{"name":"admin"},model:{value:(_vm.accessRightsData.accessRights),callback:function ($$v) {_vm.$set(_vm.accessRightsData, "accessRights", $$v)},expression:"accessRightsData.accessRights"}},[_c('b-form-checkbox',{attrs:{"value":"Training Dashboard"}},[_c('p',[_vm._v("Training Dashboard")])]),_c('b-form-checkbox',{attrs:{"value":"Set Up New Training"}},[_c('b-row',[_c('b-col',{staticClass:"pr-0",attrs:{"sm":"12","md":"4","lg":"3"}},[_c('p',[_vm._v("Set Up New Training")])]),_c('b-col',{staticClass:"px-0 text-center",attrs:{"sm":"12","md":"8","lg":"9"}},[_c('ValidationProvider',{attrs:{"name":"Set Up New Training Scope","rules":("" + ((_vm.accessRightsData.accessRights.includes('Set Up New Training')) ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"state":errors[0] ? false : ( valid ? true : null ),"disabled":!_vm.accessRightsData.accessRights.includes('Set Up New Training'),"options":_vm.scopeOptions,"name":"setUpNewTraining"},model:{value:(_vm.accessRightsData.geographicalRights.setUpNewTraining),callback:function ($$v) {_vm.$set(_vm.accessRightsData.geographicalRights, "setUpNewTraining", $$v)},expression:"accessRightsData.geographicalRights.setUpNewTraining"}}),_c('b-form-invalid-feedback',{staticStyle:{"display":"block"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-form-checkbox',{attrs:{"value":"My Trainings"}},[_c('p',[_vm._v("My Trainings")])]),_c('b-form-checkbox',{attrs:{"value":"Training Results"}},[_c('b-row',[_c('b-col',{staticClass:"pr-0",attrs:{"sm":"12","md":"4","lg":"3"}},[_c('p',[_vm._v("Training Results")])]),_c('b-col',{staticClass:"px-0 text-center",attrs:{"sm":"12","md":"8","lg":"9"}},[_c('ValidationProvider',{attrs:{"name":"Training Results Scope","rules":("" + ((_vm.accessRightsData.accessRights.includes('Training Results')) ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"state":errors[0] ? false : ( valid ? true : null ),"disabled":!_vm.accessRightsData.accessRights.includes('Training Results'),"options":_vm.scopeOptions,"name":"trainingResults"},model:{value:(_vm.accessRightsData.geographicalRights.trainingResults),callback:function ($$v) {_vm.$set(_vm.accessRightsData.geographicalRights, "trainingResults", $$v)},expression:"accessRightsData.geographicalRights.trainingResults"}}),_c('b-form-invalid-feedback',{staticStyle:{"display":"block"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-form-checkbox',{attrs:{"value":"List Trainings"}},[_c('b-row',[_c('b-col',{staticClass:"pr-0",attrs:{"sm":"12","md":"4","lg":"3"}},[_c('p',[_vm._v("List Trainings")])]),_c('b-col',{staticClass:"px-0 text-center",attrs:{"sm":"12","md":"8","lg":"9"}},[_c('ValidationProvider',{attrs:{"name":"List Trainings Scope","rules":("" + ((_vm.accessRightsData.accessRights.includes('List Trainings')) ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"state":errors[0] ? false : ( valid ? true : null ),"disabled":!_vm.accessRightsData.accessRights.includes('List Trainings'),"options":_vm.scopeOptions,"name":"listTrainings"},model:{value:(_vm.accessRightsData.geographicalRights.listTrainings),callback:function ($$v) {_vm.$set(_vm.accessRightsData.geographicalRights, "listTrainings", $$v)},expression:"accessRightsData.geographicalRights.listTrainings"}}),_c('b-form-invalid-feedback',{staticStyle:{"display":"block"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1),_c('b-tab',{attrs:{"title":"Incident Report","id":"collapse-incident-report","accordion":"access-accordion"}},[_c('b-form-checkbox-group',{staticClass:"access-rights dc-access-label",attrs:{"name":"admin"},model:{value:(_vm.accessRightsData.accessRights),callback:function ($$v) {_vm.$set(_vm.accessRightsData, "accessRights", $$v)},expression:"accessRightsData.accessRights"}},[_c('b-form-checkbox',{attrs:{"value":"My Incidents"}},[_c('p',[_vm._v("My Incidents")])])],1)],1)],1),_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"mx-auto"},[_c('button',{staticClass:"btn w-100 access-btn",attrs:{"disabled":_vm.accessRightsData.accessRights.length == 0,"type":"submit","variant":"light"}},[_vm._v(_vm._s(_vm.btnTxt))])])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }